.gallery {
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
}
.selected {
    border: 5px solid var(--color-primary);
}
