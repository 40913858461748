@import "./assets/uicons/css/uicons-regular-rounded.css";

html {
  background-color: #fff;
}

.container {
  margin: auto;
  max-width: 1198px;
  width: 100%;
}
.animated {
  transition: all 0.2s cubic-bezier(0.54, 0.1, 0, 0.99);
}

::placeholder,
.placeholder {
  color: #7b7b7b;
  font-size: 14px;
}

* {
  outline: rgba(0, 0, 0, 0);
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ai-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ai-flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ai-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.jc-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.jc-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.jc-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
form {
  max-width: 400px;
}
.col {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.margin-left {
  margin-left: 10px;
}
.margin-right {
  margin-right: 10px;
}
.margin-top {
  margin-top: 10px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.x-margin-left {
  margin-left: 50px;
}
.x-margin-right {
  margin-right: 50px;
}
.x-margin-top {
  margin-top: 50px;
}
.x-margin-bottom {
  margin-bottom: 50px;
}
.toast-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000000;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  padding: 0;
  margin: 0;
}
.info-bubble {
  &--danger {
    color: var(--color-danger);
    background-color: var(--color-danger-light);
    font-size: 17px;
    padding: 10px;
    font-weight: 600;
    border-radius: 10px;
  }
  &__link {
    text-decoration: underline;
    font-weight: 800;
    cursor: pointer;
  }
}

.pointer:hover {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}

.white {
  color: white;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.montesteur-datepicker {
  height: 42px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  border-radius: 10px;

  //background-color: var(--color-grey-light);
}

.react-datepicker {
  border-radius: 10px;
  overflow: hidden;
  border-color: var(--color-border);
  &__header {
    background-color: #fff;
    border-color: var(--color-border);
  }
  &__month-container {
    border-radius: 10px;
  }
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--color-primary);
}

.hidden {
  content-visibility: hidden;
}

.correct-answer {
  border: 5px solid var(--color-success-light);
}
.wrong-answer {
  border: 5px solid var(--color-danger);
}

.preview-img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
}

.avatar {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  border-radius: 100px;
  &__icon {
    color: aqua;
  }
}

.square-avatar {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  &__icon {
    color: aqua;
  }
}

.small-padding {
  padding: 3px;
}
.medium-padding {
  padding: 6px;
}
.large-padding {
  padding: 10px;
}
.x-large-padding {
  padding: 20px;
}

.datepicker {
  height: 42px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid var(--color-border);
  border-radius: 10px;
}

.table-body-row {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
