.view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
            flex: 1;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
            flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__menu-button {
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        color: #fff;
        background-color: var(--color-primary);
    }
    &__sidebar {
        width: 270px;
        background-color: var(--color-primary);
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &__logo {
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            z-index: 10;
            background-color: var(--color-primary);

            &__img {
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }
            &__text {
                font-weight: bold;
                color: #fff;
            }
        }

        &__links {
            margin: auto 0;
        }
        &__link {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            padding: 10px;
            border-radius: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;

            &__icon {
                margin-right: 10px;
            }
            &__label {
                margin: 0;
            }
        }
        &__link:hover {
            color: var(--color-primary);
            background-color: #fff;
        }
        &__link.active {
            background-color: #fff;
            color: var(--color-primary);
        }
        &__link + &__link {
            margin-top: 5px;
        }
    }
    &__sidebar--laptop {
    }

    &__main {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: #fff;
        position: relative;

        &__body {
            padding: 20px;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            width: 100%;
            position: relative;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            max-height: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            overflow: scroll;
        }
        &__header {
            padding: 10px 20px;
            display: flex;
            flex-direction: row;
            position: sticky;
            top: 0;

            &__title {
                font-size: 25px;
                font-weight: bold;
                margin: 0;
            }
            &__avatar {
                width: 35px;
                height: 35px;
                min-width: 35px;
                border-radius: 50%;
                background-color: var(--color-primary);
            }

            &__back-button{
                font-size: 30px;
            }
        }
        &__overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;

            &__message {
                color: #fff;
                font-size: 25px;
                font-weight: 700;
            }
        }
    }
}

.view--mobile {
    flex-direction: column;

    .mobile-warning {
        padding: 10px;
        background-color: var(--color-warning);
        color: #fff;
        font-size: 14px;
        font-weight: 700;
    }
    .view__sidebar {
        width: 100%;
        padding: 0 10px;

        &__links.close {
            display: none;
        }
        &__links.open {
            position: absolute;
            top: 80px;
            left: 0;
            bottom: 0px;
            box-sizing: border-box;
            width: 300px;
            max-width: 100%;
            overflow-y: scroll;
            background-color: var(--color-primary);
            z-index: 1;
            padding: 80px 20px 20px;
        }
    }
}
