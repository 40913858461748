.section {
    &__header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    &__status-flags {
        display: flex;
        flex-direction: row;
        max-height: 30px;
        align-items: center;
        align-content: center;
    }
}
.gallery {
    &__grid-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, 100px);
        grid-auto-rows: minmax(100px, auto);
    }
}

.gallery-img {
    background-color: aqua;
    padding: 10px;
    border-radius: 10px;
}
