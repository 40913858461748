.summary-element {
    width: 100%;
    display: flex;
    border-top: 1px solid var(--color-border);
    border-right: 1px solid var(--color-border);
    border-left: 1px solid var(--color-border);
    min-height: 40px;
    &__label {
        display: flex;
        min-width: 50%;
        width: 50%;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: var(--color-grey-light);
    }
    &__value {
        display: flex;
        min-width: 50%;
        width: 50%;
        padding: 10px 20px;
        box-sizing: border-box;
        border-left: 1px solid var(--color-border);
        word-break: break-word;
    }
}
.summary-element:last-of-type {
    border-bottom: 1px solid var(--color-border);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.summary-element:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}
