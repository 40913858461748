.dropdown {
    position: absolute;
    width: 100%;
    min-width: 200px;
    right: 0;
    min-height: 40px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
    z-index: 100000;
    color: var(--color-font);

    &--close {
        transform: scaleY(0) translateY(-100%);
        opacity: 0;
    }

    top: calc(100% + 10px);

    &__indicator {
        width: 10px;
        height: 10px;
        position: absolute;
        z-index: 10px;
        background-color: #ffffff;

        top: -4px;
        right: 15px;
        border-left: 1px solid var(--color-border);
        border-top: 1px solid var(--color-border);
        transform: rotate(45deg) translateY(-2px) translateX(-2px);
        border-top-left-radius: 5px;
        border-bottom-right-radius: 100%;
    }
    &__content {
        overflow: auto;
        max-height: 360px;
    }
}
