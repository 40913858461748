.account {
  display: flex;
  flex-direction: row;

  &__tabs {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 200px;

    &__tab {
      padding: 10px;
      border-left: 5px solid #fff;
      cursor: pointer;
    }
    &__tab + &__tab {
      border-top: 1px solid var(--color-border);
    }
    &__tab.active {
      border-left: 5px solid var(--color-primary);
      background-color: var(--color-grey-light);
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 40px;
    overflow-y: scroll;
    max-height: 100%;
  }
}

.signature {
  border: 1px solid black;
}

.help {
  &__address {
    font-weight: bold;
  }
}
