.input {
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: #fff;
    width: 100%;
    transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    box-shadow: 0 0 0 0 rgba(78, 59, 201, 0);
    min-height: 42px;
}

.input:hover {
    box-shadow: 0 0 0 4px rgba(78, 59, 201, 0.08);
}

.input + .input {
    margin-top: 10px;
}
