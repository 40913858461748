.input-select {
    position: relative;
    cursor: pointer;

    &__input-container {
        display: flex;
        position: relative;
        flex: 1;
        padding: 0 10px;
    }
    &__dropdown {
        padding: 5px;

        .input-border {
            border-color: rgba(0, 0, 0, 0.1) !important;
        }

        &__search {
            padding: 5px 5px 0 5px;
        }
        &__scrollview {
            max-height: 200px;
            overflow-y: auto;

            background:
            /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
                linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
                /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
                radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
            background:
            /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
                linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
                /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
                radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

            /* Opera doesn't support this in the shorthand */
            background-attachment: local, local, scroll, scroll;
            border-radius: 10px;
        }
    }
    .placeholder {
        margin: 0;
    }
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

    &__child {
    }
    &__label {
        display: flex;
        flex: 1;
        text-align: left;
    }
    &__selected {
    }
}
.option + .option {
    margin-top: 5px;
}
.option.selected {
    background-color: var(--color-grey-light);
    font-weight: bold;
}

.option:hover {
    background-color: var(--color-grey-light);
}
