.input-text {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-width: 150px;
    &__icon {
        height: 42px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__icon + &__input-container {
        .input {
            padding-left: 0;
        }
    }
    &__input-container {
        display: flex;
        position: relative;
        flex: 1;
        &__input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            border: none;
        }
    }
    .visible-button {
        cursor: pointer;
        color: #000;
    }
}
