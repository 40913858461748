.signin {
    &__title {
        color: #000;
    }
    &__subtitle {
        color: var(--color-grey);
    }
    &__header {
        &__back-button {
            color: var(--color-grey);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
        }
        &__infos {
            color: var(--color-grey);
            p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                text-align: right;
            }
        }
    }
}
