.preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    &__img{
        max-width: 100%;
        max-height: 100%;
    }
    &__close-button{
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
    }
}

