.field {
    max-width: 400px;
    margin-bottom: 15px;

    &__label {
        font-weight: 500;
        color: var(--color-grey);
        &__required {
            color: var(--color-danger);
        }
    }
    &__message {
        color: var(--color-danger);
        font-size: 11px;
        text-align: right;
    }
    &__body {
        padding: 5px 0;
    }
    &--error {
        .input-border {
            border-color: var(--color-danger) !important;
        }
    }
}
