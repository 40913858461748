.modal-confirmation {
    padding: 10px;

    &__body{
        padding: 10px;
    }
    &__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
}
