.info-card {
    padding: 20px;
    box-sizing: border-box;
    min-width: 250px;
    margin: 10px 0;
    height: 100px;

    &__value {
        font-size: 30px;
        font-weight: bold;
        color: #000;
        margin: 0;
    }
    &__label {
        font-size: 14px;
        color: var(--color-grey);
        font-weight: bold;
        margin: 0;
    }
}
.info-card + .info-card {
    border-left: 1px solid var(--color-border);
}
