:root {
  --color-white: #fff;

  --color-border: rgba(0, 0, 0, 0.1);

  --color-grey: #909090;
  --color-grey-light: #f7f7f7;

  --color-blue: #144eff;

  --color-primary: #004aad;
  --color-primary-contrast: #fff;
  --color-primary-light: rgb(73, 76, 255);

  --color-secondary: #8c8c8c;
  --color-secondary-contrast: #fff;
  --color-secondary-light: #d3d3d3;

  --color-tertiary: rgb(107, 231, 111);
  --color-tertiary-contrast: rgb(117, 255, 170);

  --color-danger: #f34848;
  --color-danger-contrast: #fff;
  --color-danger-light: #fa8b8b;

  --color-warning: #ffa322;
  --color-warning-contrast: #fff;
  --color-warning-light: #ffd3ae;

  --color-success: #4abf56;
  --color-success-contrast: #fff;
  --color-success-light: #aef9b5;

  --color-info: #1bb2e9;
  --color-info-contrast: #fff;
  --color-info-light: #9af0f3;

  --color-default: #fff;
  --color-default-contrast: #000;
}
