.tag {
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 800;
    display: flex;
    flex-direction: column;

    &--success {
        color: var(--color-success);
        background-color: var(--color-success-light);
    }
    &--danger {
        color: var(--color-danger);
        background-color: var(--color-danger-light);
    }
    &--warning {
        color: var(--color-warning);
        background-color: var(--color-warning-light);
    }
    &--info {
        color: var(--color-info);
        background-color: var(--color-info-light);
    }
    &--default {
        color: var(--color-default);
        background-color: var(--color-default-light);
    }
    &--primary {
        color: var(--color-primary);
        background-color: var(--color-primary-light);
    }
    &--secondary {
        color: var(--color-secondary);
        background-color: var(--color-secondary-light);
    }
    &--tertiary {
        color: var(--color-tertiary);
        background-color: var(--color-tertiary-light);
    }
    &--grey {
        color: var(--color-grey);
        background-color: var(--color-grey-light);
    }
    &__label {
        font-size: 12px;
        font-weight: 400;
    }
}
