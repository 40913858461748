.modal-gallery {
    padding: 10px;

    &__header-container {
        padding: 0px 20px 0px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    &__container {
        min-width: 1000px;
    }

    &__body {
        padding: 10px;
    }
    &__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
}

.grid-wrapper {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-auto-rows: minmax(100px, auto);
    max-height: 400px;
    overflow-y: scroll;
}
