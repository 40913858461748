.input-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0;

    &__checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;

        &__check {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid var(--color-border);
        }
        &__check.active {
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary-light);
        }
    }
    &__label {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        height: 100%;
        text-align: left;
    }
}
