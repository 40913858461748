.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;

    &__backdrop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: scroll;

        &__wrapper {
            width: 100%;
            max-height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }
    &__content {
        min-width: 290px;
        max-width: 90%;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        position: relative;
        z-index: 10;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;

            &__title {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                margin: 0;
            }

            &__close-button {
                padding: 10px;
                cursor: pointer;
                border: none;
                font-size: 20px;
                background-color: transparent;
            }

            &__close-button:active {
                transform: scale(0.9);
            }
        }
        &__body {
            padding: 20px;
        }
        &__footer {
            padding: 20px;
        }
        &__body + &__footer {
            padding-top: 0;
        }
    }
}

.width {
    min-width: 600px;
}
