.centers {
    position: relative;
    &__section {
        padding: 10px;
        margin-top: 10px;
        position: sticky;
        top: 0;
    }
    &__section-header {
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 10px;
        h2 {
            margin: 0;
        }
    }
    &__center-row {
        width: 100%;
        padding: 15px 0;
        box-sizing: border-box;

        &__avatar {
            height: 35px;
            width: 35px;
            min-width: 35px;
            background-image: url("../../assets/laboratory.png");
            border-radius: 50%;
        }
        &__label {
            font-size: 17px;
            font-weight: 600;
            margin: 0;

            &__cassette-id {
                font-size: 14px;
                color: var(--color-grey);
            }
        }
        &__sub-label {
            font-size: 14px;
            font-weight: 500;
            color: var(--color-grey);
            margin: 0;
        }
        &__testers {
            padding-top: 10px;
            display: flex;
            flex-direction: row;
        }
    }
    &__center-row + &__center-row {
        border-top: 1px solid var(--color-border);
    }
}
