.application {
  &__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  &__status-flags {
    display: flex;
    flex-direction: row;
    max-height: 30px;
    align-items: center;
    align-content: center;
  }
  &__row-buttons {
    display: flex;
    flex-direction: row;
    align-content: space-between;
  }
}

.applications {
  position: relative;
  &__section {
    padding: 10px;
    margin-top: 10px;
    position: sticky;
    top: 0;
  }
  &__section-header {
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 10px;
    h2 {
      margin: 0;
    }
  }
  &__application-row {
    width: 100%;
    padding: 15px 0;
    box-sizing: border-box;

    &__avatar {
      height: 35px;
      width: 35px;
      min-width: 35px;
      background-image: url("../../assets/laboratory.png");
      border-radius: 50%;
    }
    &__label {
      font-size: 17px;
      font-weight: 600;
      margin: 0;
    }
    &__sub-label {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-grey);
      margin: 0;
    }
  }
  &__application-row + &__application-row {
    border-top: 1px solid var(--color-border);
  }
}

.grid {
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-auto-rows: minmax(50px, auto);
  overflow-y: scroll;
}

.tags__container {
  display: flex;
  flex-wrap: wrap;
}

.idol-header {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__data {
    display: flex;
    flex-direction: row;
  }
  &__actions {
    cursor: pointer;
  }
}
