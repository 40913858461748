.view-with-form {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;

    &--laptop {
    }
    &--mobile {
        .mobile-warning {
            padding: 10px;
            background-color: var(--color-warning);
            color: #fff;
            font-size: 14px;
            font-weight: 700;
        }
        flex-direction: column;
        .view-with-form {
            &__infos {
                height: 60px !important;
                padding: 10px !important;
                flex: unset;
                &__logo {
                    &__img {
                        height: 25px;
                    }
                    margin: 0;
                    &__text {
                        font-size: 17px;
                    }
                }
            }
            &__form {
                padding: 20px;
                overflow-y: auto;
            }
        }
    }
    &__infos {
        display: flex;
        flex: 1;
        height: 100%;
        background-color: var(--color-primary);
        color: #fff;
        padding: 40px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        box-sizing: border-box;

        &__logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__img {
                margin-right: 10px;
                height: 30px;
            }
            &__text {
                color: #fff;
                font-weight: 600;
                font-size: 40px;
            }
        }
    }
    &__form {
        box-sizing: border-box;
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        overflow-y: auto;
        max-height: 100%;
        color: var(--color-primary);
        background-color: #fff;
        padding: 40px 100px;
    }
}
