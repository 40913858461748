.input-file {
    display: none;
}
.input-container {
    background-color: #32c1ec;
    border-radius: 10px;
    width: 100px;
    height: 100px;
}

.input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    height: 100%;
    color: #fff;
}
