.toast {
    padding: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    max-width: 90%;
    width: 400px;
    color: #fff;
    font-weight: 700;
    margin: 10px;
    cursor: pointer;
    animation: 0.2s 0s 1 pop;

    &__icon {
    }
    &__message {
        width: 100%;
        padding: 0 10px;
        margin: 0;
    }
    &--success {
        background-color: var(--color-success);
    }
    &--danger {
        background-color: var(--color-danger);
    }
    &--warning {
        background-color: var(--color-warning);
    }
    &--info {
        background-color: var(--color-info);
    }
    &--default {
        background-color: var(--color-default);
    }
}

@keyframes pop {
    0% {
        transform: translateY(100%) scale(0.5);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}
