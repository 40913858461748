.button {
    border-radius: 10px;
    padding: 0 10px;
    height: 42px;
    border: none;
    cursor: pointer;

    &--default {
        background-color: var(--color-default);
        color: var(--color-default-contrast);
    }
    &--primary {
        background-color: var(--color-primary);
        color: var(--color-primary-contrast);
    }
    &--danger {
        background-color: var(--color-danger);
        color: #fff;
    }
    &__loading {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .spin {
            height: 20px;
            width: 20px;
            font-size: 20px;
        }
    }
}

.button:active {
    transform: scaleY(0.9);
}
